import { clamp, distance as d, center } from "./math";
import type { Point, Box } from "./math";
export type { Box, Point };

/***

// register multiple events (simple)
const off = on(window, {mousemove, mousedown, mouseup})

// complex
const off = on(window, {
	mousemove: [move1, move2, move3, {hanlder: move4, opts: {passive: true}}],
	mouseup: {handler: up, opts: {capture: true}}
})

*/
export function on<T extends EventTarget>(el: T, events: any) {
  // unpack
  const l = Object.entries(events).flatMap(([name, conf]) => {
    return [conf].flat().map((ev) => [name, ev.handler || ev, ev.opts || {}]);
  });
  // register
  l.forEach((arg) => el && el.addEventListener(...arg));
  // off
  return () => l.forEach((arg) => el && el.removeEventListener(...arg));
}

/***
 *  Calculate a Box (x,y,w,h) for an element, and cache this calculation for `cacheForMS` ms
 */
export function box(el: HTMLElement | undefined, cacheForMS: number = 100): Box {
  if (!el) {
    return { x: 0, y: 0, w: 0, h: 0 };
  }

  if (!_boxCache.has(el)) {
    _boxCache.set(el, calcBox(el));
    setTimeout(() => _boxCache.delete(el), cacheForMS);
  }

  return _boxCache.get(el) as Box;
}

const _boxCache = new WeakMap<HTMLElement, Box>();

function calcBox(el: HTMLElement) {
  const { x, y, width, height } = el.getBoundingClientRect();

  return { x: x + window.scrollX, y: y + window.scrollY, w: width, h: height };
}

export function clientToPage<T extends Point>(p: T): T {
  p.x += window.scrollX;
  p.y += window.scrollY;
  return p;
}

function calcOffset(el: HTMLElement, f: (el: HTMLElement) => number) {
  return f(el) + (el.offsetParent ? f(el.offsetParent as HTMLElement) : 0);
}

export function DOMOffset(el: HTMLElement): { x: number; y: number } {
  return { x: calcOffset(el, (el) => el.offsetLeft), y: calcOffset(el, (el) => el.offsetTop) };
}

// Element scroll offset
export function scrollOffset(el: HTMLElement): Point {
  const p = scrollParent(el);

  return p ? point.empty(p.scrollLeft, p.scrollTop) : point.empty();
}

// get the click offset for the event, related to the given el
export function clickOffset(e: MouseEvent, targetEl: HTMLElement): Point {
  const b = calcBox(targetEl);
  return point.sum(b, point.empty(-e.pageX, -e.pageY));
}

function scrollParent(el: HTMLElement): HTMLElement | null {
  if (el == null) {
    return null;
  }

  if (el.scrollHeight > el.clientHeight) {
    return el;
  } else {
    return scrollParent(el.parentElement as HTMLElement);
  }
}

export const point = {
  sum(...points: Point[]): Point {
    const p = this.empty();
    points.forEach((i) => {
      p.x += i.x;
      p.y += i.y;
    });
    return p;
  },
  empty(x: number = 0, y: number = 0): Point {
    return { x, y };
  },
  neg(p: Point): Point {
    return { x: -p.x, y: -p.y };
  }
};

//
//
//

export const distance = {
  edge(el: HTMLElement, p: Point) {
    return d(box(el), p);
  },
  center(el: HTMLElement, p: Point = { x: 0, y: 0 }) {
    return d(center(box(el)), p);
  }
};

export function offset(el: HTMLElement, p: Point = { x: 0, y: 0 }) {
  const b = box(el);
  return { x: clamp(p.x - b.x, 0, b.w), y: clamp(p.y - b.y, 0, b.h) };
}

export function el<T extends HTMLElement = HTMLElement>(name: string, props?: Record<string, any>): HTMLElement {
  const s = document.createElement(name);
  Object.keys(props || {}).forEach((k) => {
    if (k in s) {
      // @ts-ignore
      s[k] = props![k];
    } else {
      s.setAttribute(k, props![k]);
    }
  });

  return s as T;
}

export function append(p: Element | null, el: HTMLElement): () => void {
  if (!p || !el) {
    return () => {};
  }
  p.appendChild(el);

  return () => el.parentElement === p && p.removeChild(el);
}

export function insertBefore(p: Element | null, beforeNode: Node | null | undefined, el: HTMLElement): () => void {
  if (!p || !el || !beforeNode) {
    return () => {};
  }
  p.insertBefore(el, beforeNode);

  return () => el.parentElement === p && p.removeChild(el);
}

// inject a dynamic piece of css to the head and return a cleanup function
export function css(innerText: string, target = document.head): () => void {
  return append(target, el("style", { innerText }));
}

// Inject a script to the head tag, return an "off" function to remove it
export function script(src: string, props?: Record<string, string>): () => void {
  return append(document.head, el("script", { src, type: "text/javascript", ...props }));
}






/***
 *
 * INsert text at cursor, we've moved it here because it's just 100 lines of code
 *
 *
 */

let _browserSupportsTextareaTextNodes: boolean;

function canManipulateViaTextNodes(input: HTMLElement) {
  if (input.nodeName !== "TEXTAREA") {
    return false;
  }
  if (typeof _browserSupportsTextareaTextNodes === "undefined") {
    const textarea = document.createElement("textarea");
    textarea.value = "1";
    _browserSupportsTextareaTextNodes = !!textarea.firstChild;
  }
  return _browserSupportsTextareaTextNodes;
}

export function insertTextAtCursor(input: HTMLTextAreaElement | HTMLInputElement, text: string) {
  // Most of the used APIs only work with the field selected
  input.focus();

  // IE 8-10
  // @ts-ignore
  if (document.selection) {
    // @ts-ignore
    const ieRange = document.selection.createRange();
    ieRange.text = text;

    // Move cursor after the inserted text
    ieRange.collapse(false /* to the end */);
    ieRange.select();

    return;
  }

  // Webkit + Edge
  const isSuccess = document.execCommand("insertText", false, text);
  if (!isSuccess) {
    const start = input.selectionStart || 0;
    const end = input.selectionEnd || 0;
    // Firefox (non-standard method)
    if (typeof input.setRangeText === "function") {
      input.setRangeText(text);
    } else {
      // To make a change we just need a Range, not a Selection
      const range = document.createRange();
      const textNode = document.createTextNode(text);

      if (canManipulateViaTextNodes(input)) {
        let node = input.firstChild;

        // If textarea is empty, just insert the text
        if (!node) {
          input.appendChild(textNode);
        } else {
          // Otherwise we need to find a nodes for start and end
          let offset = 0;
          let startNode = null;
          let endNode = null;

          while (node && (startNode === null || endNode === null)) {
            const nodeLength = node.nodeValue?.length || 0;

            // if start of the selection falls into current node
            if (start >= offset && start <= offset + nodeLength) {
              range.setStart((startNode = node), start - offset);
            }

            // if end of the selection falls into current node
            if (end >= offset && end <= offset + nodeLength) {
              range.setEnd((endNode = node), end - offset);
            }

            offset += nodeLength;
            node = node.nextSibling;
          }

          // If there is some text selected, remove it as we should replace it
          if (start !== end) {
            range.deleteContents();
          }
        }
      }

      // If the node is a textarea and the range doesn't span outside the element
      //
      // Get the commonAncestorContainer of the selected range and test its type
      // If the node is of type `#text` it means that we're still working with text nodes within our textarea element
      // otherwise, if it's of type `#document` for example it means our selection spans outside the textarea.
      if (canManipulateViaTextNodes(input) && range.commonAncestorContainer.nodeName === "#text") {
        // Finally insert a new node. The browser will automatically split start and end nodes into two if necessary
        range.insertNode(textNode);
      } else {
        // If the node is not a textarea or the range spans outside a textarea the only way is to replace the whole value
        const value = input.value;
        input.value = value.slice(0, start) + text + value.slice(end);
      }
    }

    // Correct the cursor position to be at the end of the insertion
    input.setSelectionRange(start + text.length, start + text.length);

    // Notify any possible listeners of the change
    const e = document.createEvent("UIEvent");
    e.initEvent("input", true, false);
    input.dispatchEvent(e);
  }
}