export type Point = { x: number; y: number };
export type Size = { w: number; h: number };
export type Box = Point & Size;

export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
export const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));
export const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
export const interpolate = (from: [number, number], to: [number, number], a: number) => lerp(to[0], to[1], invlerp(from[0], from[1], a));
export const center = ({ x, y, w, h }: Box) => ({ x: x + w / 2, y: y + h / 2 });
export const distance = (b: Point | Box, p: Point) => {
  // @ts-ignore
  const dx = Math.max(p.x - b.x, 0, p.x - (b.x + (b.w || 0)));
  // @ts-ignore
  const dy = Math.max(b.y - p.y, 0, p.y - (b.y + (b.h || 0)));
  return Math.hypot(dx, dy);
};
