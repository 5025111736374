<script lang="ts">
  /**
   * This tooltip is implemented by hoisting up the actual tooltip DOM
   * The main thing it solves in cases when the parent hierarchy contains some properties that aren't compatible with a tooltip:
   * overflow clipping, opacity, scaling etc.
   */
  import { fly } from "svelte/transition";
  import { onMount, afterUpdate } from "svelte";
  import debounce from "lodash.debounce";
  import { on } from "sbelt/dom";

  type Position = "top" | "bottom" | "top-right" | "bottom-right" | "top-left" | "bottom-left";

  // position specifies where the tooltip will appear, relative to the containing object

  export let position: Position = "bottom";
  export let delay: number = 100;
  export let width: number | undefined = undefined;
  export let mode: "hover" | "menu" = "hover";

  let ttRoot = document.getElementById("tooltip-root");
  if (!ttRoot) {
    ttRoot = document.createElement("div");
    ttRoot.id = "tooltip-root";
    ttRoot.style.cssText = "position: relative; ";
    document.body.insertBefore(ttRoot, document.body.children[0]);
  }

  let isActive = false;
  let thisEl: HTMLElement;
  let tooltipElement: HTMLElement;
  let parent: HTMLElement;
  let domRect: DOMRect;

  const enter = debounce(() => {
    if (mode === "hover") {
      open();
    }
  }, delay);

  function leave() {
    if (mode === "hover") {
      close();
    }
  }

  function open() {
    domRect = parent.getBoundingClientRect();
    isActive = true;
  }

  function close() {
    enter.cancel();
    isActive = false;
  }

  function click() {
    if (mode === "hover") {
      close();
    } else {
      if (isActive) {
        close();
      } else {
        open();
      }
    }
  }

  $: top = position.startsWith("top");
  $: bottom = position.startsWith("bottom");
  $: right = position.endsWith("-right");
  $: left = position.endsWith("-left");

  onMount(() => {
    parent = thisEl.parentNode as HTMLElement;

    const unsub = on(parent, {
      mouseenter: enter,
      click: click,
      mouseleave: leave
    });

    return () => {
      if (ttRoot && ttRoot.contains(tooltipElement)) {
        ttRoot.removeChild(tooltipElement);
      }

      unsub();
    };
  });

  let style = "";
  $: if (domRect) {
    // The Svelte container has zIndex of 99999. Let's make this higher.
    style = "z-index: 999999;";
    style += `left: ${domRect.left + window.scrollX}px;`;
    style += `width: ${width ?? domRect.width}px;`;
    if (bottom) {
      style += `top: ${domRect.bottom + window.scrollY}px;`;
    }
    if (top) {
      style += `top: ${domRect.top + window.scrollY}px;`;
    }
  }

  /**
   * Move the tooltip node to a top-level container.
   * This is done here instead of onMount() as svelte when dealing with keyed lists, may reuse the same
   * node (the `m` mount blocks instead of the `c` create blocks)
   */
  afterUpdate(() => {
    if (tooltipElement && thisEl === tooltipElement.parentNode && thisEl) {
      thisEl.removeChild(tooltipElement);
      if (ttRoot) {
        ttRoot.appendChild(tooltipElement);
      }
    }
  });
</script>

<div class="contents" bind:this={thisEl}>
  <div bind:this={tooltipElement}>
    {#if isActive}
      <div
        transition:fly|local={{ duration: 150, y: -10 }}
        class:top
        class:bottom
        class:right
        class:left
        class="absolute z-50 transform whitespace-nowrap notranslate"
        class:pointer-events-none={mode !== "menu"}
        {style}>
        <div class="z-50 flex flex-col items-center justify-center tooltip" style="z-index: 100000">
          <svg class="right-0 h-2 text-black top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"
            ><polygon class="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
          <div class="flex flex-col items-center justify-center p-2 text-sm text-white bg-black rounded content">
            <slot />
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .top {
    transform: translate(0, -100%);
  }

  .right .content {
    transform: translate(calc(50% - 20px), 0);
  }

  .left .content {
    transform: translate(calc(-50% + 20px), 0);
  }

  .top .tooltip {
    flex-direction: column-reverse;
  }

  .bottom svg {
    transform: rotate(180deg);
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
</style>
